@import "./style/mixins";

.App {
  .container {
    max-width: 1920px;
    padding: 90px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    cursor: default;
    @include breakpoint('small') {
      padding: 20px 60px 0 60px;
    }
    .header {
      .green {
        color: green;
      }
      @include breakpoint('small') {
        img {
          width: 80%
        }
      }
    }
    .content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      @include breakpoint('small') {
        flex-direction: column-reverse;
      }
      .leftPane {
        width: 45%;
        display: flex;
        flex-direction: column;
        height: calc(100vh - 330px);
        justify-content: space-between;
        margin-top: 100px;
        @include breakpoint('small') {
          width: 100%;
          height: auto;
          margin-top: 0;
          text-align: center;
          align-items: center;
          div {
            margin-top: 30px;
          }
        }
        .title {
          font-family: "BigNoodleTitling";
          font-size: 6.4em;
          font-style: oblique;
          line-height: 0.88;
          letter-spacing: -1px;
          color: #21001b;
          @include breakpoint('small') {
            font-size: 6em;
          }
        }
        .text {
          font-family: Avenir;
          font-size: 1.8em;
          line-height: 1.42;
          letter-spacing: -0.3px;
          color: #4d4d4d;
          .mail {
            font-family: Avenir;
            text-decoration: none;
            color: #4d4d4d;
            &:hover {
              color: #000000;
            }
          }
          @include breakpoint('small') {
            font-size: 1.4em;
          }
        }
        .button.googlePlay {
          border-radius: 48px;
          background-color: #30b347;
          text-align: center;
          width: 250px;
          height: 66px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: Avenir;
          font-size: 29px;
          font-weight: 900;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.81px;
          color: #ffffff;
          box-shadow: 0 2px 64px 10px rgba(48, 179, 71, 0.36);
          cursor: pointer;
          transition: all 200ms;
          @include breakpoint('small') {
            margin-bottom: 25px;
          }
          &:hover {
            transform: scale(1.02);
            transition: all 200ms;
          }
        }
      }
      .pickleLogo {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        @include breakpoint('small') {
          height: 35vh;
          margin-top: 40px;
        }
        img {
          position: absolute;
          top: 0;
          right: 0;
          width: 500px;
          @include breakpoint('small') {
            height: 100%;
            width: auto;
            top: auto;
            right: auto;
          }
          &.picklesBG {
            -webkit-animation: rotate 80s linear infinite;
            -moz-animation: rotate 80s linear infinite;
            animation: rotate 80s linear infinite;
          }
          &.picklesTop {
            transition: all 200ms;
            &:hover {
              transform: scale(1.02);
              transition: all 200ms;
            }
          }
        }
      }
    }
  }
}


@-moz-keyframes rotate { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes rotate { 100% { -webkit-transform: rotate(360deg); } }
@keyframes rotate { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

@media only screen and (max-device-width : 620px) {

}

